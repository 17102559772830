<template>
	<div>
		<div class="form-group">

			<label for="season">{{ $t('gynecologie.saison') }} *</label>
			<e-select
				v-model="season_model"
				id="season_id"
				track-by="season_id"
				label="season_label"
				:placeholder="labelTitleSeason"
				:selectedLabel="selectedLabelSeason"
				:options="seasons_tab"
				:allow-empty="false"
				:show-labels="false"
				:loading="loadingSeason"
				:class="{ 'is-invalid': errors && errors.indexOf('season') > -1 }"
			></e-select>
		</div>

		<div v-show="season_model">
			<ChangementEtalon
				ref="ChangementEtalon"
				:contract_id="contract_id" 
				:contractconfig_id.sync="contractconfig_id_local" 
				:season_id.sync="current_season_id" 
				:stallion.sync="stallion_model" 
				:modele.sync="modele_model" 
				:type_monte.sync="type_monte_model" 
				:avenant="avenant"
				:tiers="tiers"
				:tiers_tab="tiers_tab"
				:disabled="false"
				:conditions.sync="conditions_avenant_articles"
				:cmep.sync="cmep_model"
				:reload_stallion="false"
			></ChangementEtalon>
		</div>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import ContractMixin from "@/mixins/Contract.js"
	import TiersMixin from "@/mixins/Tiers.js"
	import AccountingMixin from "@/mixins/Accounting.js"
	import AccountingAccountMixin from "@/mixins/AccountingAccount.js"
	import ArticleMixin from "@/mixins/Article.js"
	export default {
		name: 'ChangementSaison',
		mixins: [Navigation, Tools, ContractMixin, TiersMixin, AccountingMixin, AccountingAccountMixin, ArticleMixin],

		props: [
			'contract_id',
			'avenant_id',
			'season_id',
			'stallion',
			'cmep',
			'modele',
			'contractconfig_id',
			'avenant',
			'tiers',
			'tiers_tab',
			'type_monte',
			'conditions',
		],
		data () {
			return {
				loadingSeason: false,
				labelTitleSeason: this.getTrad("monte.rechercher_saison"),
				selectedLabelSeason: this.getTrad("global.selected_label"),
				seasons_tab: [],
				errors: [],
				stallion_model: null,
				current_season_id: null,
				season_model: null,
				cmep_model: null,
				type_monte_model: null,
				modele_model: null,
				conditions_avenant_articles: null,
				contractconfig_id_local: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {

				this.loadingSeason = true
				this.cmep_model = this.cmep
				this.modele_model = this.modele
				this.stallion_model = this.stallion
				this.type_monte_model = this.type_monte
				this.contractconfig_id_local = this.contractconfig_id
				this.conditions_avenant_articles = this.conditions
				this.current_season_id = this.season_id
				this.avenants = await this.loadAvenantType()
			},

			async loadSeason(horse_id){
				let contract_config = await this.loadContractsConfig(horse_id)

				let seasons = []
				let seasons_ids = []

				for (let i = 0; i < contract_config.length; i++) {
					if(seasons_ids.indexOf(contract_config[i].season.season_id) == -1){
						seasons.push(contract_config[i].season)
						seasons_ids.push(contract_config[i].season_id)
					}
				}

				this.seasons_tab = seasons
				this.loadingSeason = false
			},

		},

		watch: {
			stallion_model(val){
				if(val != null){
					this.loadSeason(val.horse_id)
				}
			},
			season_model(val){
				this.current_season_id = val.season_id
			}
		},

		components: {
			ChangementEtalon : () => import('@/components/Contract/AvenantType/ChangementEtalon'),
			
		}
	}

</script>